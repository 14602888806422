const eventSchemas = {
  /* trackPageView */
  pageview: ({
    pagePath = window.location.pathname,
    pageTitle = document.title,
    locale = null,
    buuid = null,
    email = null
  }) => {
    return {
      pagePath,
      pageTitle,
      locale,
      buuid,
      email
    }
  },

  /* trackProductListingImpression */
  productImpression: ({ products, listName, currency }) => ({
    ecommerce: {
      currencyCode: currency,
      impressions: products.map((product, position) => ({
        name: product.displayTitle,
        id: product.ecomId,
        price: product.price.cents / 100,
        brand: "Bleach London",
        category: product.range || product.stage,
        list: listName,
        position
      }))
    }
  }),

  // Suppressing this event until payload schema is confirmed
  // productClick: ({ displayTitle, ecomId, price, stage }) => ({
  //   ecommerce: {
  //     click: {
  //       products: [
  //         {
  //           name: displayTitle, // Name or ID is required.
  //           id: ecomId,
  //           price: price.cents / 100,
  //           brand: "Bleach London",
  //           category: stage
  //         }
  //       ]
  //     }
  //   }
  // }),

  /* trackButtonClick */
  ctaClick: ["ctaText", "ctaLink", "location"],

  /* HAIR QUIZ */
  hair_quiz: {
    intro: {
      loaded: []
    },
    started: [],
    step: {
      loaded: ["step_index", "quiz_type", "step_name"]
    },
    submitted: ["quiz_type"], // Also expects relevant hair state attributes and their values
    incompatible: {
      loaded: ["quiz_type"]
    },
    recommendations: {
      loaded: ["quiz_type", "recommended_products"]
    },
    auth_skip: {
      clicked: []
    }
  },

  search_result: {
    enabled: [],
    add_to_cart: ["product_id", "product_name", "search_query"],
    selected: ["product_id", "product_name", "search_query"]
  },

  /* Hero Banner */
  hero_banner: {
    clicked: ["link_source", "sku"]
  },

  /* All products CTA - ( /products, /bundles ) */
  stage: {
    clicked: ["link_source", "link_target"]
  },

  /* Navigation Bar menu */
  navigation: {
    hamburger: {
      clicked: []
    },
    menu: {
      loaded: ["nav_entry_id", "nav_entry_title"],
      item_selected: ["menu_title", "item_title", "interaction_type"]
    },
    promo: {
      clicked: ["item_title"]
    }
  },

  // Subscription Events

  subscriptions: {
    listing_text: {
      clicked: []
    },
    option: {
      selected: []
    }
  },

  registration: {
    completed: []
  },

  log_in: {
    started: [],
    completed: ["user_id"]
  },

  /* Gallery - ( best seller products, youtube, etc ) */
  gallery: {
    product: { clicked: ["link_source", "product_id", "product_name"] },
    youtube: { clicked: ["link_source"] }
  },

  /* Recommended Product in PDP */
  related_product: {
    primary: {
      clicked: ["link_source", "sku"]
    },
    secondary: {
      clicked: ["link_source", "sku"]
    }
  },

  change_store: {
    confirm: {
      clicked: ["current_locale", "target_locale", "context"]
    },
    reject: {
      clicked: ["current_locale", "target_locale", "context"]
    }
  }
}

export default {
  /**
   *
   * @param {string} event - The event key. Uses dot notation syntax to reference nested objects
   * @param {Object} payload - The values being passed from the event trigger.
   */
  trackEvent: function(event, payload) {
    const eventObj = getEventFromString(eventSchemas, event)

    let eventPayload = {}

    // if the event has a function mapping the input payload to the schema (eg migration of legacy support), use it
    if (typeof eventObj === "function") {
      eventPayload = eventObj(payload)
    } else {
      // make sure 'expected' properties have a value
      for (const prop of eventObj) {
        if (!Object.prototype.hasOwnProperty.call(payload, prop))
          console.error(`Missing prop ${prop} from event ${event}`)
        eventPayload[prop] = payload[prop] || null
      }
      // ...chuck everything else in - Caedite eos. Novit enim Dominus qui sunt eius.
      Object.assign(eventPayload, payload)
    }

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event,
      ...eventPayload
    })
  },

  /// LEGACY EVENTS - TODO: migrate these to the newer structure above

  trackProductListingClick: ({ displayTitle, ecomId, price, stage, range }) => {
    window.dataLayer.push({
      event: "productClick",
      ecommerce: {
        click: {
          products: [
            {
              name: displayTitle, // Name or ID is required.
              id: ecomId,
              price: price.cents / 100,
              brand: "Bleach London",
              category: range || stage // Supporting deprecation of 'stage' field PP-1884
            }
          ]
        }
      }
    })
  },

  trackProductDetailsView: ({
    displayTitle,
    price,
    ecomId,
    stage,
    sellingPlanGroups
  }) => {
    window.dataLayer.push({
      event: "productDetails",
      ecommerce: {
        detail: {
          products: [
            {
              name: displayTitle,
              id: ecomId,
              price: price.cents / 100,
              brand: "Bleach London",
              category: stage,
              has_subscription: sellingPlanGroups.length > 0
            }
          ]
        }
      }
    })
  },
  trackBundleDetailView: ({ displayTitle, id, sellingPlanGroups }, price) => {
    window.dataLayer.push({
      event: "BundleDetail.loaded",
      ecommerce: {
        detail: {
          products: [
            {
              name: displayTitle,
              id: id,
              price,
              brand: "Bleach London",
              has_subscription: sellingPlanGroups.length > 0
            }
          ]
        }
      }
    })
  },
  trackGiftCardView: () => {
    window.dataLayer.push({
      event: "productDetails",
      ecommerce: {
        detail: {
          products: [
            {
              name: "Gift Card",
              brand: "Bleach London",
              category: "giftCard"
            }
          ]
        }
      }
    })
  },
  trackAddToCart: (
    quantity,
    { displayTitle, ecomId, stage, price, sellingPlanGroups },
    currency,
    customData,
    actionTarget
  ) => {
    window.dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currencyCode: currency,
        add: {
          products: [
            {
              name: displayTitle,
              id: ecomId,
              price: price.cents / 100,
              brand: "Bleach London",
              category: stage,
              quantity: quantity,
              isSelfRecipient: !(customData && customData.recipient),
              ...(customData &&
              customData.sellingPlanId &&
              sellingPlanGroups.length > 0
                ? {
                    frequency:
                      sellingPlanGroups[0].sellingPlans[0]
                        .orderIntervalFrequency +
                      " " +
                      sellingPlanGroups[0].sellingPlans[0].orderIntervalUnitType
                  }
                : null)
            }
          ]
        }
      },
      action_target: actionTarget
    })
  },

  trackLookCollectionPageView: lookCollectionName => {
    window.dataLayer.push({
      event: "look_category.loaded",
      look_category: lookCollectionName
    })
  },
  trackAddProductToBundle: (lookName, stageName, ecomId, productName) => {
    window.dataLayer.push({
      event: "look_detail_product.selected",
      stage_name: stageName,
      look_name: lookName,
      product_id: ecomId,
      product_name: productName
    })
  },
  trackLookStageView: stageName => {
    window.dataLayer.push({
      event: "stage_detail.loaded",
      stage_name: stageName
    })
  },
  trackHowToLandingView: () => {
    window.dataLayer.push({
      event: "ht_transformation_list.loaded"
    })
  },
  trackTransformationLandingView: transformationName => {
    window.dataLayer.push({
      event: "ht_transition_list.loaded",
      transformation_name: transformationName
    })
  },
  trackTransitionAddProduct: (ecomId, productName) => {
    window.dataLayer.push({
      event: "ht_transition_detail_product.selected",
      product_id: ecomId,
      product_name: productName
    })
  },
  trackTransitionLandingView: (transformationName, transitionName) => {
    window.dataLayer.push({
      event: "ht_transition_detail.loaded",
      transition_name: transitionName,
      transformation_name: transformationName
    })
  },
  trackSalonsLandingView: () => {
    window.dataLayer.push({
      event: "salons.loaded"
    })
  },
  trackPastOrders: ordersLength => {
    window.dataLayer.push({
      event: "account_past_orders_list.loaded",
      nu_orders: ordersLength
    })
  },
  trackPastOrderView: () => {
    window.dataLayer.push({
      event: "account_past_order.loaded"
    })
  },
  trackPastOrderReorder: () => {
    window.dataLayer.push({
      event: "account_past_order_reorder.selected"
    })
  },
  trackLoadConsultation: () => {
    window.dataLayer.push({
      event: "consultation.loaded"
    })
  },
  trackBookConsultation: () => {
    window.dataLayer.push({
      event: "consultation.confirmed"
    })
  },
  trackCancelConsultation: () => {
    window.dataLayer.push({
      event: "consultation.canceled"
    })
  }
}

////////////////////////////
//// UTILS

function getEventFromString(object, str) {
  let obj = { ...object }
  let parts = str.split(".")
  while (parts.length > 0) {
    obj = obj[parts.shift()]
  }
  return obj
}
